import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
// import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useUsersList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'userToken', label: '#', sortable: false },
    { key: 'name', sortable: false },
    { key: 'email', sortable: false },
    { key: 'profileName', label: 'Perfil', sortable: false },
    { key: 'status', label: 'status', sortable: false },
    { key: 'actions', label: 'Ações' },
  ]

  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('name')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
    refetchData()
  })

  const fetchUsers = (ctx, callback) => {
    store
      .dispatch('app-user/fetchUsers', {
        // q: searchQuery.value,
        // perPage: perPage.value,
        // page: currentPage.value,
        // sortBy: sortBy.value,
        // sortDesc: isSortDirDesc.value,
        // role: roleFilter.value,
        // plan: planFilter.value,
        // status: statusFilter.value,
        SearchText: searchQuery.value,
        PageSize: perPage.value,
        PageNumber: currentPage.value - 1,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        // role: roleFilter.value,
        // plan: planFilter.value,
        // status: statusFilter.value,
      })
      .then(response => {
        // const { users, total } = response.data

        const users = response.data.result.objectLists[0]
        const total = response.data.result.totalResults

        callback(users)
        totalUsers.value = total
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Erro ao listar os usuários',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        return error
      })
  }

  const handleDeleteUser = token => {
    store.dispatch('app-user/deleteUser', {
      token,
    })
      // .then(setTimeout(refetchData(), 300))
      .then(toast({
        component: ToastificationContent,
        props: {
          title: 'Usuário excluído com sucesso!',
          icon: 'AlertTriangleIcon',
          variant: 'success',
        },
      }))
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Ocorreu um erro ao tentar excluir o usuário.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
      .finally(() => refetchData())
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const getInitialsUser = name => {
    const colors = ['#1abc9c', '#2ecc71', '#3498db', '#9b59b6', '#34495e', '#16a085', '#27ae60', '#2980b9', '#8e44ad', '#2c3e50', '#f1c40f', '#e67e22', '#e74c3c', '#95a5a6', '#f39c12', '#d35400', '#c0392b', '#bdc3c7', '#7f8c8d']
    const initials = name.split(' ').length > 1 ? name.split(' ')[0].charAt(0).toUpperCase() + name.split(' ')[1].charAt(0).toUpperCase() : name.split(' ')[0].charAt(0).toUpperCase()

    const charIndex = initials.charCodeAt(0) - 65
    const colorIndex = charIndex % 19

    const bgColor = colors[colorIndex]

    return {
      initials, bgColor,
    }
  }

  // const resolveUserRoleIcon = role => {
  //   if (role === 'subscriber') return 'UserIcon'
  //   if (role === 'author') return 'SettingsIcon'
  //   if (role === 'maintainer') return 'DatabaseIcon'
  //   if (role === 'editor') return 'Edit2Icon'
  //   if (role === 'admin') return 'ServerIcon'
  //   return 'UserIcon'
  // }

  // const resolveUserStatusVariant = status => {
  //   if (status === 'pending') return 'warning'
  //   if (status === 'active') return 'success'
  //   if (status === 'inactive') return 'secondary'
  //   return 'primary'
  // }

  const resolveUserStatusVariant = status => {
    if (status === 'Ativada') return 'success'
    if (status === 'Pendente') return 'warning'
    return 'warning'
  }

  return {
    fetchUsers,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    handleDeleteUser,

    resolveUserRoleVariant,
    // resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,
    getInitialsUser,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  }
}
