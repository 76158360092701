import axios from '@axios'
import useJwt from '@/auth/jwt/useJwt'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // fetchUsers(ctx, queryParams) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .get('/apps/user/users', { params: queryParams })
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },
    // fetchUser(ctx, { userToken }) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .get(`/apps/user/users/${userToken}`)
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        useJwt.listUsers(queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUser(ctx, { userToken }) {
      return new Promise((resolve, reject) => {
        useJwt.getUserSystem({ token: userToken })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProfileList() {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/profiles')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteUser(ctx, userToken) {
      return new Promise((resolve, reject) => {
        useJwt.deleteUser(userToken)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/user/users', { user: userData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
